* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
.w100 {
  width: 100%;
}
.mt20 {
  margin-top: 20px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.alignCenter {
  text-align: center;
}
.query-builder {
  margin: 0 !important;
}
.Mui-checked span {
  color: #182C52;
}
.query-builder {
  .group {
    background: #e7effe;
    border: 1px solid #182C52;
    padding: 15px 20px;
    .group--conjunctions {
      margin-left: 0;
    }
    .group-or-rule-container {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0;
    }
  }
  .rule--value {
    margin-left: 10px;
    input {
      height: auto;
    }
  }
  .rule--operator {
    margin-left: 20px;
  }
  .group--children > .group-or-rule-container > .group-or-rule::before,
  .group--children > .group-or-rule-container > .group-or-rule::after {
    content: '';
    border-color: #0b4ec1;
    left: -15px;
    width: 15px;
  }
  .group--children.hide--line
    > .group-or-rule-container
    > .group-or-rule::before,
  .group--children.hide--line
    > .group-or-rule-container
    > .group-or-rule::after {
    border-color: #0b4ec1;
  }
}

/* ______________________________________________________________________________________ */.floatingField{
  position: relative;
  .floatingLabel {
    color: #929497;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 9px;
    top: 17px;
    background: #fff;
    padding: 0 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .floatingInput {
    font-size:14px;
    display:block;
    width:100%;
    height:54px;
    padding: 0 14px;
    background: #fff;
    color: #929497;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    box-sizing: border-box;
    &:focus{
      outline:none;
      border: 2px solid #182C52;
    }
  }
  .floatingInput:focus ~ .floatingLabel,
  .floatingInput.active ~ .floatingLabel,
  input.flatpickr-input:not([value=""]) ~ .floatingLabel  {
    top:-3px;
    transform: translate(14px, -6px) scale(0.75);
    left: -19px;
  }
  .floatingInput:focus ~ .floatingLabel{
    color: #182C52;
    font-weight: 500;
  }
  &:hover{
    .floatingInput {
    border: 1px solid #929497;
    }
  }
}

.action_delete{
  padding: 4px 8px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 3px;
  border:1px solid #E01F29;
  background-Color: #FBE8E9;
  color:  #E01F29;
  width: auto;
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;
  &:hover{
    opacity: 0.9;
  }
}
 .action_view{
  padding: 4px 8px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 3px;
  border:1px solid #0159FE;
  background-Color: #E5EEFF;
  color:  #0159FE;
  width: auto;
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;
  &:hover{
    opacity: 0.9;
  }
}
.action_edit{
  padding: 4px 8px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 3px;
  color: #000;
  border:1px solid #24A148;
  background-Color: #EAF6ED ;
  color:  #24A148;
  &:hover{
    opacity: 0.9;
  }
  width: auto;
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;
  &:hover{
    opacity: 0.9;
  }
}
.MuiAccordionDetails-root {display: inherit!important;}

.dialogProgressbar .colorBar {
  height: 100%;
  border-radius: inherit;
  text-align: center;
}

.dialogProgressbar .colorBar span {
  padding: 0 10px;
  font-size: 14px;
  position: absolute;
  top: 9px;
  left: 0;
}

.dialogProgressbar .valStyles {
  position: absolute;
  top: 9px;
  right: 6px;
  font-size: 10px;
  display: inline-block;
  color: #404041;
}

.liftStyles {
  width: 25%;
  float: left;
  line-height: 3;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.liftStyles span + span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.liftStyles svg {
  font-size: 30px;
}


.autoCompleteArea {
  .MuiAutocomplete-tag.MuiChip-root{
    margin: 0;
    background: transparent;
    color: inherit;
    height: auto;
    text-align: left;
  }
}