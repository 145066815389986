path {
    /* fill: #add8e6; */
    /* fill: #FBD395; */
    /* fill: #C5E3EC; */
  }

  .leaflet-container {
    height: 600px;
    width: 100%;
  }
  
  .leaflet-div-icon {
    background: transparent;
    border: none;
  }